import React from 'react';
import AssemblyLogo from '../img/Assembly_Square_Logo.png';

export function Footer() {
  return (
    <footer style={styles.footer}>
      <p id='footertext'>© 2024 Seyong Ahn in HAS ASSEMBLY<img src={AssemblyLogo} alt='Assembly Logo' style={{width: '30px'}}/></p>
      {/* <hr id='footerhr'/>
      <span id='footerspan'>
        |<p className='footerp'><a className='footera' href='https://github.com/hiseyong'>GitHub</a></p>|<p className='footerp'><a className='footera' href='https://instagram.com/i_don.t_count'>Instagram</a></p>|
      </span> */}
    </footer>
  );
};

const styles = {
  footer: {
    backgroundColor: 'white',
    textAlign: 'center',
    padding: '0',
    position: 'fixed',
    left: '0',
    bottom: '0',
    width: '100%',
    height: '30px',
    'font-size': '12px'
  },
};
